$publicPath:'';

@import "./../../../assets/css/global-variable.scss";
.g-code-add-box {
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.m-tt {
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.u-tree {
  float: left;
  min-width: 200px;
}
